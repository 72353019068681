.pressreleases {

  .year-filter {
    .year-filter-menu {
      display: inline-block;
      border-top: 1px solid $specialGrey;
      border-bottom: 1px solid $specialGrey;
      padding-left: 0;
      padding: 10px 0px;
      margin-bottom: 20px;

      .year-filter-item {
        display: inline;
        list-style: none;
        margin-right: 10px;
        padding: 5px 8px;

        &:last-child {
          margin-right: 0;
        }
        &:hover {
          background-color: $almostWhite;
        }
        .year-filter-item-link {
          text-transform: uppercase;

          &:hover {
            text-decoration: none;
          }
        }
        &.active {
          background-color: $specialGrey;

          a {
            color: $white !important;
          }
        }

      }
    }
  }
  .pressrelease-item {
    margin-bottom: 20px;

     a {
      display: block;
       margin-bottom: 10px;

      h4 {
        font-weight: bold;
        margin-bottom: 0;
      }
     }

    .date {
      font-weight: bold;
      margin-right: 5px;
      font-size: 110%;
    }

    .pressrelease-link-more {
      font-weight: bold;
    }
  }
  .pagination {

    li {
      list-style: none;
      display: inline;

      &>span, &>a{
        background: none;
        border: none;
        color: black;
        font-size: 15pt;

        &:after {
          color: #516DB9;
          content: '\00B7';
          font-size: 50px;
          margin-left: 13px;
          margin-right: -5px;
          vertical-align: -12%;
        }
      }

      &.previous, &.next {
        display: none;
      }

      &:nth-last-child(2) {
        &>span:after, &>a:after{
          display: none;
        }
      }
    }
  }
}

.pressrelease {

  .subtitle {
    margin-top: 0;
  }
  .date {
    font-weight: bold;
    margin-right: 5px;
    font-size: 110%;
  }
  .title-image {
    float:left;
    width: 350px;
    margin-right: 15px;
  }

  h4 {
    margin-top: 20px;
  }
}
