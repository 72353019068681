.cc-grower {
  position: fixed;
  z-index: 99999;
}

.cc-revoke {
  position: fixed;
  z-index: 999999;

  @media (hover: hover) {
    right: 5em !important;
    left: unset !important;
    background-color: #f4bd00 !important;
  }

  // Move the revoke button to the left screen border if the pointer device does not support hovering
  @media (hover: none) {
    left: 0 !important;
    top: 80px !important;
    padding: 5px 8px;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0 !important;
  }
}

p.noconsent-notice {
  font-size: 1em;

  b {
    font-size: 1.1em;
  }

  a {
    color: #d16f65;

    &:hover {
      text-decoration: underline;
    }
  }
}
