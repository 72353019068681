.announcement-item-wrapper {
  background-color: #F7F7F7;
  margin-bottom: 30px;

  .announcement-title {
    font-weight: bold;
    margin-left: 15px;
    max-width: 70%;
  }

  .announcement-info {
    margin-bottom: 10px;

    &>p>span:first-of-type {
      font-weight: bold;
    }

    .post-type-link-more {
      border: 1px solid grey;
      padding: 3px 15px 3px 15px;
      text-decoration: none;
      text-transform: uppercase;
      margin-left: 20px;
    }
  }

  .announcement-icon {
    background: white;
    border-radius: 50%;
    height: 70px;
    margin-top: -10px;
    padding: 20px;
    padding-top: 15px;
    width: 70px;

    &>.glyphicons {
      color: black;
      font-size: 20pt;
    }

    &>p {
      font-size: 13pt;
      margin-left: -4px;
      font-weight: bold;
    }
  }
}
