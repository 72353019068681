#page {
  header {
    position: fixed;
    width: calc(100% - 30px);
    margin: 0 15px;
    background-color: $white;
    z-index: 101;

    .header-logo {
      position: relative;
      padding: 10px;
      background-color: white;
      z-index: 9;

      a {
        display: table;
      }
    }
  }

  main {
    width: 100%;

    a {
      color: $grey;

      &:hover {
        border-radius: 0;
      }
    }

    .header-image {
      height: 30vh;
      background-size: cover;
    }

    @import "blog-post-list-last-notice-asset.scss";

    .content {
      background-size: cover;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 25px;

      & > .row {
        margin-left: -15px;
        // @media (min-width: $sm-view) {
        //   margin-left: -30px;
        // }
      }

      &.yellow {
        .neos-nodetypes-text {
          a {
            color: $yellow;

            &:hover {
              color: $yellow !important;
              border-color: $yellow !important;
            }
          }
        }
      }

      &.red {
        .neos-nodetypes-text {
          a {
            color: $red;

            &:hover {
              color: $red !important;
              border-color: $red !important;
            }
          }
        }
      }

      &.blue {
        .neos-nodetypes-text {
          a {
            color: $blue;

            &:hover {
              color: $blue !important;
              border-color: $blue !important;
            }
          }
        }
      }

      &.green {
        .neos-nodetypes-text {
          a {
            color: $green;

            &:hover {
              color: $green !important;
              border-color: $green !important;
            }
          }
        }
      }

      #addon-collection {
        width: 180px;
        padding: 10px;

        .neos-nodetypes-text {
          font-size: 12px;
        }
      }

      @import "Breadcrumb-Menu/mobile.scss";
      @import "Submenu/mobile.scss";
      @import "Contact-Person/mobile.scss";
      @import "Openings/mobile.scss";
      @import "post-type-filter-menu.scss";
      @import "archived-switch.scss";
      @import "blog-post-list.scss";

      //Content NodeTypes
      @import "NodeTypes/text-with-image.scss";
      @import "NodeTypes/tabs.scss";
      @import "NodeTypes/collapsibles.scss";
      @import "NodeTypes/assetlist.scss";
      @import "NodeTypes/gallery.scss";
      @import "NodeTypes/contact-person.scss";
      @import "NodeTypes/announcements.scss";

      .content-inner {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 50px;

        h1 {
          font-size: 35px;
        }
      }
    }
  }

  footer {
    padding-left: 15px;
    width: 100%;
    margin-bottom: 30px;

    .divider {
      margin: 15px 0;

      &:after {
        content: "";
        display: block;
        width: 224px;
        position: relative;
        left: -15px;
        border-bottom: 2px solid $grey;
      }
    }

    .footer-top .footer-logo img {
      max-width: 40%;

      @media (max-width: $lg-view) {
        max-width: 60%;
      }

      @media (max-width: $md-view) {
        max-width: 80%;
      }

      @media (max-width: 767px) {
        max-width: 20%;
      }

      @media (max-width: 550px) {
        max-width: 25%;
      }

      @media (max-width: 450px) {
        max-width: 33%;
      }

      @media (max-width: 350px) {
        max-width: 40%;
      }
    }

    a {
      color: $grey;
    }

    .footer-menu {
      margin-left: 30px;
      padding-top: 5px;

      a {
        font-weight: 400;
        text-transform: uppercase;

        &:hover {
          text-decoration: none;
        }
      }

      .footer-menu-item {
        margin-right: 5px;

        &:after {
          content: "";
          border-right: 1px solid $grey;
          margin-left: 6px;
        }

        + .last-item {
          margin-right: 0;

          &::after {
            display: none;
          }
        }
      }
    }
  }
}

@media (min-width: $sm-view) {
  #page {
    header {
      width: 100%;
      margin: 10vh 0 0;
    }

    main {
      position: relative;
      width: 75%;
      margin-top: 10vh;

      .header-image {
        width: 100%;
      }

      .content {
        background-size: cover;
        padding-left: 30px;
        padding-right: 0;

        @import "Breadcrumb-Menu/sm.scss";
        @import "Submenu/sm.scss";
        @import "Contact-Person/sm.scss";
        @import "Openings/sm.scss";

        .content-inner {
          h1 {
            font-size: 35px;
          }
        }
      }
    }

    footer {
      width: 100%;
      padding-left: 30px;
      position: relative;
      margin-bottom: 50px;

      .footer-top {
        float: right;
        width: 22%;
        position: fixed;
        bottom: 10vh;
        right: 1vw;

        a {
          color: $grey;
        }

        .footer-menu {
          float: none;
          width: auto;
          margin-left: 30px;
          margin-top: -5px;

          .footer-menu-item {
            font-weight: 400;

            &:after {
              border-right: 1px solid $grey;
            }
          }
        }
      }

      .divider {
        &:after {
          left: -30px;
        }
      }

      .copyright {
        width: 200px;
      }
    }
  }
}

@media (min-width: $md-view) {
  #page {
    main {
      .content {
        margin-top: 50px;
        @import "Contact-Person/md.scss";
        @import "Openings/md.scss";
      }
    }
  }
}

@media (min-width: $lg-view) {
  #page {
    main {
      .content {
        margin-top: 70px;

        @import "Breadcrumb-Menu/lg.scss";
        @import "Submenu/lg.scss";
        @import "Contact-Person/lg.scss";
        @import "Openings/lg.scss";

        .content-inner {
          padding-top: 50px;
          padding-bottom: 50px;
        }
      }
    }
  }
}
