//Contact-Button Color settings

&.yellow {

 #openings {
   > a {
     background-color: $yellow;
   }

   .openings-inner{
     h5 {
       color: $yellow;
     }
   }

   .openings-footer {

     i.glyphicons {
       color: $yellow;
     }

     a:hover {
       color: $yellow;
     }
   }
 }
}

&.green {

  #openings {
    > a {
      background-color: $green;
    }

    .openings-inner{
      h5 {
        color: $green;
      }
    }
    .openings-footer {
      i.glyphicons {
        color: $green;
      }

      a:hover {
        color: $green;
      }
    }
  }
}

&.red {

  #openings {
    > a {
      background-color: $red;
    }

    .openings-inner{
      h5 {
        color: $red;
      }
    }
    .openings-footer {
      i.glyphicons {
        color: $red;
      }

      a:hover {
        color: $red;
      }
    }
  }
}

&.blue {
  #openings {
    > a {
      background-color: $blue;
    }

    .openings-inner{
      h5 {
        color: $blue;
      }
    }
    .openings-footer {
      i.glyphicons {
        color: $blue;
      }

      a:hover {
        color: $blue;
      }
    }
  }
}

.page-addons {
  position: absolute;
  right: 0;
  top: -90px;
}

#openings {
  position: relative;
  right: 5px;


  > a#openings-button {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    color: $white;
    display: block;
    padding: 11px;
    margin-top: 10px;

    i.glyphicons {
      font-size: 15px;
    }
  }

  .openings-inner{
    position: absolute;
    right: 0;
    top: 60px;
    opacity: 0;
    background-color: $white;
    padding: 10px;
    min-width: 170px;
    box-shadow: 0px 1px 10px 0 rgba(0,0,0,0.3);
    transition: opacity .3s;

    &.active {
      opacity: 1;
      z-index: 999;
    }

    .close-contact {
      color: $grey;

      i {
        font-size: 14px;
      }
    }

    h5 {
      font-size: 17px;
    }

    .openings-body {
      p {
        font-size: 12px;
        margin-bottom: 0;
      }

      &:after {
        content: '';
        display: block;
        width: 65px;
        border-bottom: 2px solid $grey;
        margin: 15px 0;
      }
    }

    .openings-footer {
      font-size: 12px;
      table {
        tbody {
          tr {
            &:last-child {
              td {
                border-bottom: none
              }
            }
            td {
              border-top: none;
              border-bottom: 1px solid $almostWhite;

              &:last-child {
                background-color: $almostWhite;
              }
            }
          }
        }
      }
    }
  }
}
