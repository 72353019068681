#openings {
  position: relative;

  .openings-inner {

    .openings-body {
      margin-left: 20px;

      &:after {
        margin: 15px -15px;
      }
    }
  }
}
