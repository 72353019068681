@mixin search {

  .transition-container {
    display: flex;
    position: relative;
    top: 0;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transition: .6s top ease-in-out;
    width: 100%;
    height: 60px;
    @media (min-width: $sm-view) { width: 80%; }
    @media (min-width: $md-view) { width: 60%; }
    @media (min-width: $lg-view) { width: 50%; }
    @media (min-width: 1440px) { width: 41%; }

    &.transition-container-active {
      top: -80px;
      height: 70vh;

      @media (min-width: $sm-view) { height: 60vh; }
    }

    .outer-wrapper {
      width: 100%;

      form {
        position: relative;

        .search-param {
          border-radius: 0;
          height: 47px;
          border: none;
        }

        div.button {
          position: absolute;
          top: 18px;
          right:12px;
          color: $lightgrey;
          border: none;
          background: transparent;

          &:focus {
            outline: none;
          }
        }
      }
      .search-results-wrapper {
        position: relative;
        max-height: calc(70vh - 50px);
        margin-top: 10px;
        opacity: 0;
        transition: all .2s;
        transition-timing-function: ease-in;
        background-size: 100px;
        background-color: white;
        background-position: center;
        background-repeat: no-repeat;
        box-shadow: inline 0 0 5px 0 rgba(204,204,204,1);
        z-index: 9;

        &.search-results-active {
          opacity: 1;
          height: 70vh;
          @media (min-width: $sm-view) { height: 60vh; }
          transition-timing-function: ease-out;
          overflow: scroll;
          overflow-x: hidden;

        }

        &.contact-details-active {
          overflow: hidden;
        }

        .search-results {
          transition: all .2s;
          transform: translateX(0);
          transition-timing-function: ease-in-out;
          color: $grey;
          //height: 100%;
          height: auto;

          &.contact-person-details-active {
            transform: translateX(-230px);
            @media (min-width: 375px ) { transform: translateX(-240px); }
            @media (min-width: $sm-view) { transform: translateX(-60%); }
            transition-timing-function: ease-in-out;
          }

          .loader {
            margin-top: 25%;
            margin-bottom: 25%;
            .fa-spinner{
              font-size: 30px;
            }
            p {
              margin-top: 10px;
            }
          }
          .no-results {
            padding: 20px;
          }

          // Color settings
          .search-result-item {
            .result-wrapper {
              .result-item-main {
                h5 {
                  &.yellow {
                    &:after {
                      border-bottom-color: $yellow;
                    }
                  }
                }
              }
              .item-footer {
                .category-number.yellow {
                  &::after {
                    border-bottom-color: $yellow;
                  }
                }
              }
            }
          }

          .search-result-item {
            .result-wrapper {
              .result-item-main {
                h5 {
                  &.red {
                    &:after {
                      border-bottom-color: $red;
                    }
                  }
                }
              }
              .item-footer {
                .category-number.red {
                  &::after {
                    border-bottom-color: $red;
                  }
                }
              }
            }
          }

          .search-result-item {
            .result-wrapper {
              .result-item-main {
                h5 {
                  &.green {
                    &:after {
                      border-bottom-color: $green;
                    }
                  }
                }
              }
              .item-footer {
                .category-number.green {
                  &:after {
                    border-bottom-color: $green;
                  }
                }
              }
            }
          }

          .search-result-item {
            .result-wrapper {
              .result-item-main {
                h5 {
                  &.blue {
                    &:after {
                      border-bottom-color: $blue;
                    }
                  }
                }
              }
              .item-footer {
                .category-number.blue {
                  &::after {
                    border-bottom-color: $blue;
                  }
                }
              }
            }
          }

          .search-result-item {
            border-bottom: 2px solid $almostWhite;
            padding: 15px 15px;
            background-color: white;
            transition: height 0.2s;

            &:hover {
              background-color: $almostWhite;
            }

            .result-wrapper {
              color: $grey;
              &:hover {
                text-decoration: none;
              }
              .result-item-main {
                width: 70%;
                h5 {
                  font-size: 16px;
                  position: relative;
                  margin: 0;

                  &:after {
                    content: '';
                    display: block;
                    width: 55px;
                    margin-top: 6px;
                    margin-bottom: 5px;
                    margin-left: -15px;
                    border-bottom: 2px solid $specialGrey;
                  }
                }
                a {
                  color: $grey;
                  display: block;
                  &:hover {
                    text-decoration: none;
                  }
                }

                i.glyphicons {
                  margin-right: 5px;
                  font-size: 11px;
                  margin-top: 3px;
                }
              }
              .item-footer {

                .category-number {
                  font-size: 30px;
                  text-align: right;
                  line-height: 1;
                  width: 50px;

                  .post-icon {
                    margin-bottom: 5px;
                  }

                  &::after {
                    content: '';
                    display: block;
                    width: 50px;
                    border-bottom: 2px solid $specialGrey;
                  }
                }

                .category-title {
                  text-align: right;
                  width: 50px;
                  line-height: 1;
                  margin-top: 2px;
                }

                .filetypes {
                  font-size: 30px;
                }
                button {
                  border: none;
                  background-color: $white;
                  margin-top: 15px;
                  margin-right: -14px;
                  padding: 10px 10px 10px 5px;

                  &:focus {
                    outline: none;
                  }

                  i.glyphicons {
                    font-size: 20px;
                    transform: rotate(0deg);
                    transition: all .2s;
                    transition-timing-function: ease-out;

                    &.rotate {
                      transform: rotate(180deg);
                    }
                  }
                }
              }
            }
            .contact-details {
              position: absolute;
              top: 0;
              right: -100%;
              visibility: hidden;
              transition: all .6s;
              transition-timing-function: ease-out;
              width: 100%;
              height: 70vh;
              @media (min-width: $sm-view) { height: 60vh;}
              background-color: $almostWhite;
              box-shadow: inset 6px 0px 15px -8px rgba(0,0,0,0.3);
              overflow: hidden;

              &.contact-details-active {
                visibility: visible;
                overflow: visible;
              }
              @import "contact-person.scss";

              #contact-person-nodetype {
                position: static;
                margin-top: 10px;
                margin-left: 5px;
                @media (min-width: $sm-view) {  margin-top: 25px; margin-left: 15px;  }
                max-width: 215px;
                color: $specialGrey;

                .spacing {
                  margin-bottom: 20px;
                }

                h5 {
                  margin-top: 0;
                }

                .salutation {
                  position: absolute;
                  @media (min-width: $sm-view) {
                    top: 60px;
                    left: 25px;
                  }

                  top: 50px;
                  left: 15px;
                }

                #contact-icon {
                  width: 37px;
                  height: 37px;
                  border-radius: 50%;
                  color: $white;
                  display: block;
                  padding: 11px;
                  margin-top: 10px;
                  float: right;
                  background-color: $specialGrey;

                  i.glyphicons {
                    font-size: 15px;
                  }
                }

                #room {
                  margin-left: 20px;
                }

              }
              &.yellow {
                #contact-person-nodetype {
                  #contact-icon {
                    background-color: $yellow;
                  }
                }
              }
              &.red {
                #contact-person-nodetype {
                  #contact-icon {
                    background-color: $red;
                  }
                }
              }
              &.green {
                #contact-person-nodetype {
                  #contact-icon {
                    background-color: $green;
                  }
                }
              }
              &.blue {
                #contact-person-nodetype {
                  #contact-icon {
                    background-color: $blue;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.neos-search {
  @include search;
}
