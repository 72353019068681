.page-cloud {
  position: absolute;
  top: 250px;
  max-width: 465px;
  height: 200px;

  @media (min-width: $sm-view) { top: 200px; }

  a {
    display: inline-block;
    margin-right: 10px;
    color: $white;
    white-space: nowrap;

    &:hover {
      color: $yellow;
      text-decoration: none;
    }
  }
}
