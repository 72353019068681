//* Base text colors *//
$grey: #3d3d3d;
$specialGrey: #666666;
$lightgrey: #95989a;
$almostWhite: #eeeeee; //#F7F7F7;
$almostWhiteTransparent: rgba(247, 247, 247, 0.6);
$white: #ffffff;

$formBG: #F7F7F7;

// Category colors
$yellow: #f4bd00;
$red: #d16f65;
$green: #a1bf65;
$blue: #7595c9;

//* Breadcrumb Font-Size Settings *//

//Landing page ---
$categoryNumberLandingXS: 45px;
$categoryTitleLandingXS: 18px;
$categoryActiveLandingXS: 18px;
$categoryCurrentLandingXS: 31px;

$categoryNumberLandingSM: 50px;
$categoryTitleLandingSM: 23px;
$categoryActiveLandingSM: 23px;
$categoryCurrentLandingSM: 36px;

$categoryNumberLandingMD: 50px;
$categoryTitleLandingMD: 23px;
$categoryActiveLandingMD: 23px;
$categoryCurrentLandingMD: 36px;

$categoryNumberLandingLG: 60px;
$categoryTitleLandingLG: 33px;
$categoryActiveLandingLG: 33px;
$categoryCurrentLandingLG: 36px;

//Default page ---
$categoryNumberXS: 38px;
$categoryTitleXS: 15px;
$categoryActiveXS: 15px;
$categoryCurrentXS: 23px;

$categoryNumberSM: 38px;
$categoryTitleSM: 15px;
$categoryActiveSM: 15px;
$categoryCurrentSM: 23px;

$categoryNumberMD: 38px;
$categoryTitleMD: 15px;
$categoryActiveMD: 15px;
$categoryCurrentMD: 36px;

$categoryNumberLG: 50px;
$categoryTitleLG: 23px;
$categoryActiveLG: 23px;
$categoryCurrentLG: 36px;
