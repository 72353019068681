@import 'cookie-consent.scss';

@import "fonts.scss";
@import 'variables.scss';
@import 'swiper.scss';

@mixin contrast {
  @at-root {
    body.letter-contrast & {
      color: black !important;
      transition: 0.3s color ease-in-out !important;
    }
  }
}

body {
  font-family: "source-sans-pro", sans-serif;
  font-size: 13px;
  color: $grey;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  overflow-x: hidden;

  @include contrast;

  &.letter-size-1 {
    font-size: 15px;
  }

  &.letter-size-2 {
    font-size: 17px;
  }

  &.letter-contrast {
    color: black !important;
  }

  a.blog-link-back {
    margin-left: 30px;
    margin-top: 15px;
    position: absolute;
    border: 1px solid $specialGrey;
    font-size: 10px;
    padding: 2px 6px;
  }

}

strong, b {
  color: $specialGrey;

  @include contrast;
}

hr {
  border-color: $lightgrey;

  @include contrast;
}

@import 'home.scss';
@import 'default.scss';
@import 'landing.scss';
@import 'table.scss';
@import 'form.scss';
@import 'file-types.scss';
@import 'NodeTypes/indexed-ajax-search.scss';
@import 'NodeTypes/alert-box.scss';
@import 'menu.scss';
@import 'page-cloud.scss';
@import 'external-forms.scss';
@import 'press-releases.scss';


@media (min-width: $sm-view) {}

@media (min-width: $md-view) {}

@media (min-width: $lg-view) {

  body {
    font-size: 15px;

    &.letter-size-1 {
      font-size: 17px;
    }

    &.letter-size-2 {
      font-size: 19px;
    }
  }
}
