//color settings

&.yellow {
  .tabs {
    .nav-tabs {
      border-color: $yellow;

      + .tab-collection {
        border-bottom: 1px solid $yellow;
      }

      &.nav-stacked {
        + .tab-collection {
          border-right-color: $yellow;

          @media (max-width: $sm-view - 1) {border-top-color: $yellow}
        }
      }

      > li.active {
        > a {
          background-color: $yellow;
          color: $white;
        }
      }
    }
    .tab-collection {
      border-left-color: $yellow;
    }
  }
}

&.red {
  .tabs {
    .nav-tabs {
      border-color: $red;

      + .tab-collection {
        border-bottom: 1px solid $red;
      }

      &.nav-stacked {
        + .tab-collection {
          border-right-color: $red;
          @media (max-width: $sm-view - 1) {border-top-color: $red}
        }
      }

      > li.active {
        > a {
          background-color: $red;
          color: $white;
        }
      }
    }
    .tab-collection {
      border-left-color: $red;
    }
  }
}

&.blue {
  .tabs {
    .nav-tabs {
      border-color: $blue;

      + .tab-collection {
        border-bottom: 1px solid $blue;
      }

      &.nav-stacked {
        + .tab-collection {
          border-right-color: $blue;
        }
      }

      > li.active {
        > a {
          background-color: $blue;
          color: $white;
        }
      }
    }
    .tab-collection {
      border-left-color: $blue;
    }
  }
}

&.green {
  .tabs {
    .nav-tabs {
      border-color: $green;

      + .tab-collection {
        border-bottom: 1px solid $green;
      }

      &.nav-stacked {
        + .tab-collection {
          border-right-color: $green;
        }
      }

      > li.active {
        > a {
          background-color: $green;
          color: $white;
        }
      }
    }
    .tab-collection {
      border-left-color: $green;
    }
  }
}

.tabs {
  margin-bottom: 20px;

  .nav-tabs {
    margin-bottom: 0;

    &.nav-stacked {
      border-bottom: none;

      + .tab-collection {
        border-right: 1px solid;
        border-bottom: none;

        @media (max-width: $sm-view - 1) {
          border-right: none;
          border-left: none;
          border-top: 1px solid
        }
      }

      > li {
        + li {
          margin-top: 5px;
        }
        > a {
          margin-right: -1px;
        }
      }
    }

    > li {
      margin-bottom: 0;

      &:last-child {
        > a {
          margin-right: 0;
        }
      }

      > a {
        border-color: transparent;
        border-radius: 0;
        margin-right: 5px;
        color: $grey;
        background-color: $almostWhite;
      }
    }
  }

  .tab-collection {
    background-color: $almostWhite;
    padding: 15px;
  }
}
