
.blog-post-list {
  margin-left: -30px;

  .post-item {
    background-color: $almostWhite;
    margin-bottom: 20px;
    padding: 30px;
    position: relative;

    .post-type-news, .post-type-notice {

      .meta-wrapper {
        position: static;
        @media (min-width: $sm-view) {position: relative;};

      }

      .post-type-image {
        @media (min-width: $md-view) {margin: 0}
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 10px;
        padding-bottom: 100%;
        width: 100%;
      }
      .post-type-meta {
        h3 {
          display: inline-block;
          font-size: 20px;
          @media (min-width: $sm-view) {font-size: 18px;}
          margin-right: 10px;
          margin-top: 0;
        }
        p {
          margin-bottom: 15px;
        }
        .post-type-link-more {
          border: 1px solid $specialGrey;
          bottom: 40px;
          @media (max-width: $sm-view) {bottom: -88px;}
          font-size: 12px;
          padding: 2px 6px;
          position: relative;
          z-index: 1;
          @media (min-width: $sm-view) {position: static; font-size: 14px;}

          &:hover {
            background-color: $specialGrey;
            color: $white;
            text-decoration: none;
          }

          &.download-button {
            margin-left: 5px;
          }
        }
      }
      .post-type-icon {
        background-color: $white;
        border-radius: 100%;
        float: right;
        height: 70px;
        margin-top: 20px;
        padding: 15px;
        text-align: center;
        width: 70px;
        @media (min-width: $sm-view) {
          width: 96px;
          height: 96px;
          padding: 20px;
          margin-top: 0
        };

        .glyphicons {
          font-size: 26px;
          @media (min-width: $sm-view) { font-size: 35px}

          &.glyphicons-bullhorn {
            margin-top: 2px;
            @media (min-width: $sm-view) {margin-top: 8px;}
          }
        }

        .post-type-year {
          font-weight: 700;
          @media (min-width: $sm-view) { font-size: 17px;}
        }
      }
    }

    .post-type-notice {
      .post-type-icon {
        padding: 14px;

        .post-type-year {
          line-height: 1;
        }
      }
    }
  }

  .pagination {
    li {
      &>span, &>a{
        background: none;
        border: none;
        color: black;
        font-size: 15pt;

        &:after {
          color: #516DB9;
          content: '\00B7';
          font-size: 50px;
          margin-left: 13px;
          margin-right: -5px;
          vertical-align: -12%;
        }
      }

      &.previous, &.next {
        visibility: hidden;
      }

      &:nth-last-child(2) {
        &>span:after, &>a:after{
          visibility: hidden;
        }
      }
    }
  }
}

.blog-post-info-wrapper {
  margin-top: 50%;
  @media (max-width: $sm-view) {
    margin-top: -50%;
  }

  div.post-icon {
    background-color: #666666;
    border-radius: 50%;
    color: white;
    font-size: 20pt;
    height: 50px;
    padding: 12px;
    width: 50px;
  }

  span.post-date {
    color: #95989A;
    font-size: 15pt;
    margin-top: 7px;
    text-align: right;
  }

  .underscore {
    background-color: #95989a;
    border-radius: 5px;
    height: 2px;
    margin-top: 20px;
    width: 60px;
  }
}
