#contact-person, #addon-collection {

  a#contact-button, a#addon-button {
    float: right;
  }

  .contact-inner {
    display: block;
    box-shadow: none;
    position: static;

    .contact-footer {
      margin-left: 20px;
      box-shadow: none;
      display: block;

      a {
        cursor: pointer;
      }

      #mail {
        a {
          cursor: pointer;
        }
      }
    }
  }
}
