// breadcrumb color seetings

&.yellow {
  .category-number, .current {
    color: $yellow;
  }
  .normal, .active {
    &:before {background-color: $yellow;}
  }
}

&.red {
  .category-number, .current {
    color: $red;
  }
  .normal, .active {
    &:before {background-color: $red;}
  }
}

&.green {
  .category-number, .current {
    color: $green;
  }
  .normal, .active {
    &:before {background-color: $green;}
  }
}

&.blue {
  .category-number, .current {
    color: $blue;
  }
  .normal, .active {
    &:before {background-color: $blue;}
  }
}

//#######################

.breadcrumb-menu {
  margin-top: 20px;

  .category {
    position: relative;
    display: inline-block;;

    .category-number {
      font-size: $categoryNumberLandingXS;


      &::after {
        content: '';
        display: block;
        width: 55px;
        position: absolute;
        left: -15px;
        border-bottom: 2px solid $specialGrey;
      }
    }
    .category-title {
      color: $specialGrey;
      font-size: $categoryTitleLandingXS;
      margin-top: 10px;
    }
  }

  .normal, .active {

    &:before {
      content: '';
      width: 5px;
      height: 5px;
      display: inline-block;
      border-radius: 50%;
      margin-bottom: 2px;
      margin-left: 5px;
      margin-right: 5px;

    }

    a {
      color: $specialGrey;
      font-size: $categoryActiveLandingXS;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .current {
      font-size: $categoryCurrentLandingXS;
      font-weight: 900;
  }

}
