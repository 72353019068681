// styling
.neos-nodetypes-html, .php-script {
  input[type="text"] {
    background: $formBG;
    border: none;
    box-shadow: none;
    border-radius: 0;
  }

  button[type="submit"] {
    box-shadow: none;
    border-radius: 0;
  }

  a.btn {
    border-radius: 0;
    margin: 5px;
  }

  form[role="search"] {
    padding: 0;

    .input-group.add-on {
      border-bottom: 2px solid $lightgrey;

      input#srch-term, button[type="submit"] {
        background: $formBG;
        border: none;
        box-shadow: none;
        border-radius: 0;

        .glyphicon-search {
          vertical-align: middle;
          color: $lightgrey;
        }
      }
    }
  }

  .dropdown>button.dropdown-toggle, select {
    background: $formBG;
    border-radius: 0;
    border: none;
    box-shadow: none;

    .caret {
      margin: 7px 0px 7px 7px;
    }
  }

  textarea {
    border-radius: 0;
    resize: none;
    border: none;
    background: $formBG;
  }
}

// coloring
.content.yellow {
  .neos-nodetypes-html, .php-script {
    button[type="submit"], input[type="submit"], a.btn {
      background: $yellow;
      color: $grey;
    }
  }
}

.content.red {
  .neos-nodetypes-html, .php-script {
    button[type="submit"], input[type="submit"], a.btn {
      background: $red;
      color: $white;
    }
  }
}

.content.green {
  .neos-nodetypes-html, .php-script {
    button[type="submit"], input[type="submit"], a.btn {
      background: $green;
      color: $white;
    }
  }
}

.content.blue {
  .neos-nodetypes-html, .php-script {
    button[type="submit"], input[type="submit"], a.btn {
      background: $blue;
      color: $white;
    }
  }
}
