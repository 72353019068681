#page {
  main {
    .neos-nodetypes-text {

      a {
        white-space: nowrap;
    
        &:hover {
          text-decoration: none;
        }
    
        &:before {
          position: relative;
          top: 3px;
          display: inline-block;
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          border-right: 1px solid $grey;
          margin-right: 3px;
          margin-left: 3px;
        }
      }
    
      //external links
      a[href*='//']:before {
        content: '\E389';
        font-family: 'Glyphicons Regular';
        padding-right: 5px;
      }
    
      //internal links
      a[href^='/']:before {
        content: '\E037';
        font-family: 'Glyphicons Regular';
        padding-right: 3px;
      }
    
      // pdf
      a[href$='.pdf']:before {
        content: '\E065';
        font-family: 'Glyphicons Filetypes';
        padding-right: 3px;
      }
    
      // doc
      a[href$='.doc']:before {
        content: '\E002';
        font-family: 'Glyphicons Filetypes';
        padding-right: 3px;
      }
    
      // exel
      a[href$='.xls']:before {
        content: '\E069';
        font-family: 'Glyphicons Filetypes';
        padding-right: 3px;
      }
    
      // csv
      a[href$='.csv']:before {
        content: '\E012';
        font-family: 'Glyphicons Filetypes';
        padding-right: 3px;
      }
    }
  }
}