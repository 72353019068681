@mixin glyphicon {
  font-family: 'Glyphicons Regular';
  font-style: normal;
  font-weight: normal;
}

@mixin glyphicon-envelope {
  @include glyphicon;
  content: '\E011';
}

@mixin glyphicon-file {
  @include glyphicon;
  content: '\E037';
}

@mixin glyphicon-folder-open {
  @include glyphicon;
  content: '\E145';
}

@mixin glyphicon-remove {
  @include glyphicon;
  content: '\E208';
}

@mixin glyphicon-chevron-left {
  @include glyphicon;
  content: '\E225';
}

@mixin glyphicon-exit {
  @include glyphicon;
  content: '\E389';
}

@mixin glyphicon-folder-closed {
  @include glyphicon;
  content: '\E441';
}

@mixin glyphicon-phone {
  @include glyphicon;
  content: '\E443';
}

@mixin glyphicon-fax {
  @include glyphicon;
  content: '\E450';
}

@mixin color {
  &.yellow {
    color: $yellow;
  }

  &.red {
    color: $red;
  }

  &.green {
    color: $green;
  }

  &.blue {
    color: $blue;
  }
}

.menu-triggers {
  position: absolute;
  top: 0;
  right: 0;
  margin: 17px 17px 0 0;

  @media (min-width: $sm-view) {
    width: calc(25% - 24px);
  }

  .nav-button {
    display: block;
    position: absolute;
    right: 0;
    width: 25px;
    height: 25px;
    text-decoration: none;
    cursor: pointer;
    z-index: 9;

    @media (min-width: $sm-view) {
      position: relative;
      width: 100%;
      margin-top: 20px;
      padding-left: 20px;
    }

    .icon-bar {
      display: block;
      position: absolute;
      top: 0;
      width: 25px;
      height: 4px;
      border-radius: 1px;
      background-color: $specialGrey;
      transition: 0.4s all ease-in-out;

      &:nth-of-type(2) {
        top: 7px;
      }

      &:nth-of-type(3) {
        top: 14px;
      }
    }

    &.menu-open {
      .icon-bar {
        &:nth-of-type(1) {
          top: -15px;
          opacity: 0;
        }

        &:nth-of-type(2) {
          transform: rotate(45deg);
        }

        &:nth-of-type(3) {
          top: 7px;
          transform: rotate(-45deg);
        }
      }
    }

    @media (min-width: $sm-view) {
      .menu-title,
      .menu-title-close {
        display: block;
        position: absolute;
        top: -8px;
        padding-left: 40px;
        color: $lightgrey;
        font-size: 25px;
        opacity: 1;
        transition: 0.4s all ease-in-out;

        @include contrast;
      }

      .menu-title-close {
        padding-left: 30px;
        opacity: 0;
      }

      &.menu-open {
        .menu-title {
          opacity: 0;
        }

        .menu-title-close {
          opacity: 1;
        }
      }
    }
  }

  .sub-triggers {
    transition: 0.6s all ease-in-out;

    .to-main-menu {
      display: block;
      width: calc(100% - 40px);
      max-width: 150px;
      height: 35px;
      margin: 20px 20px 0;
      padding-top: 9px;
      color: $lightgrey;
      background-color: $almostWhite;
      font-size: 16px;
      text-align: center;
      text-decoration: none;
      opacity: 0;
      cursor: pointer;
      transition: inherit;

      @include contrast;

      .glyphicons {
        position: relative;
        left: -5px;

        @media (min-width: $md-view) {
          left: -10px;
        }
      }
    }

    .move-down {
      position: relative;
      top: -55px;
      transition: inherit;
      transition-delay: 0.2s;

      .search-button {
        display: none;
        position: relative;
        width: 100%;
        margin-top: 20px;
        padding-left: 45px;
        color: $lightgrey;
        font-size: 15px;
        line-height: 16px;
        text-decoration: none;
        cursor: pointer;
        transition: inherit;

        @include contrast;

        @media (min-width: $sm-view) {
          display: block;

          &:before {
            content: '';
            display: block;
            position: absolute;
            left: -7px;
            bottom: -10px;
            width: 60px;
            height: 2px;
            background-color: $lightgrey;
            opacity: 0;
            transition: inherit;
          }

          &.active {
            &:before {
              opacity: 1;
            }
          }

          .glyphicons {
            position: absolute;
            left: 20px;

            &:before {
              position: relative;
              left: -10px;
              padding: 0 10px;
            }
          }
        }
      }

      .contact-persons-button {
        @extend .search-button;
      }

      .lettering-controls {
        margin-top: 40px;

        .size-button,
        .contrast-button {
          @extend .search-button;

          padding-left: 20px;
          cursor: default;

          .glyphicons {
            position: absolute;
            left: 100px;
            cursor: pointer;
            transition: 0.4s all ease-in-out;

            &.glyphicons-minus {
              left: 75px;
              opacity: 0;
              transition: 0.4s all ease-in-out;

              &.visible {
                opacity: 1;
              }
            }

            &.glyphicons-plus {
              opacity: 1;

              &.invisible {
                visibility: visible;
                opacity: 0.4;
              }
            }
          }
        }

        .contrast-button {
          margin-top: 10px;

          & .glyphicons:after {
            content: '';
            position: absolute;
            top: 1px;
            left: 8px;
            width: 0;
            height: 14px;
            background-color: black;
            border-radius: 50%;
            transition: 0.2s all ease-in-out;

            .letter-contrast & {
              left: 1px;
              width: 14px;
            }
          }
        }
      }
    }

    &.active {
      .to-main-menu {
        opacity: 1;
        transition-delay: 0.2s;
      }

      .move-down {
        top: 0;
        transition-delay: 0s;
      }
    }
  }
}

#main-menu {
  position: absolute;
  top: -100vh;
  left: -15px;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding-top: 51px;
  background-color: $white;
  opacity: 0.8;
  overflow: hidden;
  transition: 0.5s all ease-in-out;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 51px;
    background-color: $white;
  }

  @media (min-width: $sm-view) {
    top: -10vh;
    left: -100vw;
    width: 75%;
    height: 100vh;
    padding: 10vh 0;
    opacity: 1;
    overflow: visible;

    &:after {
      display: none;
    }
  }

  @media (min-width: $md-view) {
    transition: .7s all ease-in-out;
  }

  &.menu-open {
    top: 0;
    opacity: 1;

    @media (min-width: $sm-view) {
      top: -10vh;
      left: 0;
    }
  }

  .nav {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: 0;
    left: 0;
    height: calc(100% - 30px);
    padding-left: calc(50% - 50px);
    background-color: $almostWhite;
    overflow: hidden;
    transition: 0.5s all ease-in-out;
    z-index: auto;

    @media (min-width: $sm-view) {
      display: block;
      height: 100%;
      padding: 30px 0 0 40px;
      box-shadow: 0 0 5px 0 rgba(204,204,204,1);
      overflow-y: scroll;
    }

    > li {
      position: static;

      @media (min-width: $sm-view) {
        width: calc(50% - 40px);
      }

      @media (min-width: $md-view) {
        float: left;
        width: 25%;
      }
    }

    .open-submenu {
      color: $grey;
      margin-bottom: 10px;
      color: $lightgrey;
      font-size: 20px;
      background-color: transparent !important;

      @include contrast;

      .category-id {
        font-size: 41px;

        @include color;

        &:after {
          content: '';
          display: block;
          position: relative;
          top: -5px;
          left: -20px;
          width: 60px;
          border-bottom: 2px solid $lightgrey;
        }
      }

      .status-indicator {
        display: block;
        position: absolute;
        top: 20px;
        left: 65px;
        width: 17px;
        height: 17px;
        background-color: $lightgrey;
        border-radius: 50%;

        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 8px;
          left: 4px;
          width: 9px;
          height: 1px;
          background-color: $white;
          transition: 0.3s all ease-in-out;
        }

        &:after {
          top: 8px;
          left: 4px;
          transform: rotate(-90deg);
        }
      }

      @media (max-width: $md-view - 1) {
        &.submenu-open .status-indicator:after {
          transform: rotate(0deg);
        }
      }
    }

    .submenu {
      display: none;
      position: fixed;
      top: 51px;
      right: -100%;
      width: 100vw;
      height: calc(100% - 81px);
      padding: 20px 20px 20px 50px;
      background-color: $almostWhite;
      list-style: none;
      overflow: auto;
      transition: .5s all ease-in-out;

      @media (min-width: $sm-view) {
        position: absolute;
        right: 30px;
        width: 50%;
        height: calc(80vh - 250px);
        padding: 30px;
        background-color: $white;
      }

      @media (min-width: $md-view) {
        display: block !important;
        position: relative;
        top: 15px;
        right: auto;
        width: auto;
        height: auto;
        padding: 0 0 0 15px;
        background-color: transparent;
      }

      li {
        transition: inherit;

        & > a {
          display: block;
          position: relative;
          margin: 0 0 15px 20px;
          padding-left: 5px;
          color: $specialGrey;
          border-left: 1px solid $lightgrey;
          line-height: 15px;
          text-decoration: none;

          @include contrast;

          &:before {
            @include glyphicon-file;
            position: absolute;
            left: -20px;
            color: $lightgrey;
            transition: inherit;

            @include contrast;
          }

          &[href*='//']:before {
            @include glyphicon-exit;
          }
        }

        .category-menu {
          display: none;
          position: fixed;
          top: 10vh;
          left: -75%;
          width: 75%;
          height: 80vh;
          padding: 30px 0 0 40px;
          background-color: $almostWhite;
          list-style: none;
          transition: inherit;
          z-index: 9;

          @media (min-width: $md-view) {
            display: block;
          }

          &.category-menu-open {
            left: 0;

            .close-category-menu {
              transform: rotate(-180deg);
            }
          }

          .close-category-menu {
            position: absolute;
            right: 10px;
            top: 10px;
            width: 30px;
            height: 30px;
            padding: 6px;
            color: $white;
            background-color: $lightgrey;
            border-radius: 50%;
            font-size: 18px;
            transition: .7s all ease-in-out;
            transform: rotate(0deg);
            cursor: pointer;
          }

          li {
            margin-right: calc(70% + 28px);

            a {
              padding: 0 10px;

              &.active,
              &.active:before {
                min-height: 35px;
                background-color: $white;
                border-left: 2px solid $almostWhite;
                line-height: 35px;

                @include color;
              }

              &:before {
                top: 0;
                left: -40px;
                height: 100%;
                padding: 0 10px;
              }

              &[href="#"]:before {
                @include glyphicon-folder-closed;
              }

              &[href="#"].active:before {
                @include glyphicon-folder-open;
              }
            }

            .category-submenu {
              display: none;
              position: absolute;
              top: 30px;
              right: 50px;
              width: calc(70% - 50px);
              height: 75%;
              max-height: calc(100% - 170px);
              padding: 35px;
              background-color: $white;
              overflow: scroll;

              &.active {
                display: block;
              }

              .image {
                width: 100%;
                height: 120px;
                margin-bottom: 50px;
                background-color: $almostWhite;
                background-size: cover;

                @media (min-width: $lg-view) {
                  height: 180px;
                }
              }

              ul {
                list-style: none;
                column-count: 2;

                li {
                  display: table;
                  margin-right: 20px;
                  -webkit-column-break-inside: avoid;
                  page-break-inside: avoid;
                  break-inside: avoid-column;
                }
              }
            }
          }

          .category-wrapper {
            display: block;
            right: 100px;

            .category {
              bottom: calc(10vh + 20px);
              opacity: 1;

              .category-id {
                display: inline-block;
                position: relative;

                &:after {
                  content: '';
                  display: block;
                  position: absolute;
                  bottom: 10px;
                  right: 0;
                  width: 85px;
                  border-bottom: 2px solid $lightgrey;
                }
              }

              .category-title {
                display: block;
                position: relative;
                top: -5px;
                color: $lightgrey;
                font-size: 20px;

                @include contrast;
              }
            }
          }
        }
      }

      .close-submenu-wrapper {
        position: absolute;
        left: 0;
        width: 30px;
        height: 80px;
        transition: inherit;

        @media (min-width: $sm-view) {
          display: none;
        }

        .close-submenu {
          position: fixed;
          top: 81px;
          width: 30px;
          height: 80px;
          background-color: white;
          cursor: pointer;
          transition: inherit;

          @include color;

          &:after {
            @include glyphicon-chevron-left;
            position: absolute;
            top: calc(50% - 13px);
            left: calc(50% - 9px);
            font-size: 19px;
            transition: inherit;
          }
        }
      }

      &:not(.submenu-open) .close-submenu:after {
        transform: rotate(180deg);
      }

      .category-wrapper {
        position: absolute;
        right: 10px;
        width: 60px;
        height: 60px;

        @media (min-width: $sm-view) {
          display: none;
        }

        .category {
          position: fixed;
          bottom: 20px;
          opacity: .5;

          .category-id {
            font-size: 60px;

            @include color;
          }

          .category-title {
            display: none;
          }
        }
      }
    }
  }

  &.submenu-open {
    @media (max-width: $sm-view - 1) {
      .nav {
        left: -100%;
      }

      .submenu {
        right: 0%;
      }
    }
  }

  .supplement-menu-trigger {
    display: block;
    position: relative;
    top: 0;
    width: 100%;
    height: 30px;
    padding-top: 5px;
    color: $specialGrey;
    background-color: $white;
    font-size: 19px;
    text-align: center;
    transition: inherit;
    cursor: pointer;

    @include contrast;

    @media (min-width: $sm-view) {
      display: none;
    }

    .glyphicons {
      transition: inherit;
    }
  }

  .supplement-menu {
    display: none;
    position: relative;
    top: 0;
    left: 0;
    height: calc(100vh - 30px);
    padding: 50px 30px;
    background-color: $almostWhite;
    transition: inherit;
    z-index:  5;

    @media (min-width: $sm-view) {
      display: block;
      position: absolute;
      top: auto;
      bottom: 10vh;
      left: auto;
      right: 30px;
      width: 230px;
      height: auto;
      padding: 0;
    }

    &.search-open {
      left: -100vw;
    }

    .supplement-menu-item {
      display: block;
      position: relative;
      float: left;
	    width: calc(50% - 15px);
      margin-bottom: 30px;
      background-color: $white;
      box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.16);

      &:nth-of-type(odd) {
        margin-right: 30px;
      }

      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      @media (min-width: $sm-view) {
        &.search,
        &.contact-persons {
          display: none;
        }
      }

      .content {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-left: 20px;

        .content-inner {
          color: $lightgrey;
          font-size: 15px;
          word-wrap: break-word;

          @include contrast;

          .glyphicons {
            display: block;
            margin-bottom: 10px;
            font-size: 25px;

            &:after {
              content: '';
              display: block;
              position: relative;
              top: 7px;
              left: -20px;
              width: 45px;
              border-bottom: 2px solid $lightgrey;
            }
          }
        }
      }
    }
  }

  &.supplement-menu-open {
    .nav {
      top: calc(-100% + 32px);
    }

    .submenu {
      top: calc(-100% + 135px);
    }

    .supplement-menu-trigger {
      top: calc(-100% + 32px);

      .glyphicons {
        transform: rotate(-180deg);
      }
    }

    .supplement-menu {
      top: calc(-100% + 32px);
    }
  }
}

#menu-search {
  position: fixed;
  top: 83px;
  right: -100vw;
  width: 100vw;
  height: calc(100vh - 83px);
  margin: 0;
  padding: 30% 45px 15px;
  color: $specialGrey;
  background-color: $almostWhite;
  overflow: hidden;
  transition: 0.5s all ease-in-out;
  z-index: 9;

  @include contrast;

  &.search-open,
  &.contact-persons-open {
    right: 0;
  }

  @media (min-width: $sm-view) {
    right: auto;
    left: -100vw;
    top: 10vh;
    width: 75%;
    height: 80vh;
    padding-top: 25px;
    overflow: visible;
    box-shadow: 0 0 5px 0 rgba(204,204,204,1);

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 10vh;
      top: -10vh;
      left: 0;
      background-color: $white;
    }

    &:after {
      top: auto;
      bottom: -10vh;
    }

    &.search-open,
    &.contact-persons-open {
      right: auto;
      left: 0;
    }
  }

  .search-title h1 {
    font-weight: bold;
    max-width: 350px;
  }

  .search-page-cloud {
    .page-cloud {
      top: auto;

      a {
        color: $specialGrey;

        @include contrast;
      }
    }
  }

  .close-search-wrapper,
  .close-contact-persons-wrapper {
    position: absolute;
    left: 0;
    width: 30px;
    height: 80px;
    transition: inherit;

    @media (min-width: $sm-view) {
      display: none;
    }

    .close-search,
    .close-contact-persons {
      position: fixed;
      top: 101px;
      width: 30px;
      height: 80px;
      background-color: white;
      cursor: pointer;
      transition: inherit;
      color: $specialGrey;

      @include contrast;

      &:after {
        @include glyphicon-chevron-left;
        position: absolute;
        top: calc(50% - 13px);
        left: calc(50% - 9px);
        font-size: 19px;
        transition: inherit;
      }
    }
  }

  &:not(.search-open) .close-search:after,
  &:not(.contact-persons-open) .close-contact-persons:after {
    transform: rotate(180deg);
  }

  @include search;

  .neos-search {
    .transition-container {
      &.transition-container-active {
        transform: translateY(-35%);

        @media (min-width: $sm-view) {
          transform: none;
        }

        .search-results-wrapper {
          .search-results {
            .search-result-item {
              .contact-details {
                background-color: $white;
              }
            }
          }
        }
      }
    }
  }

  .search-signet,
  .contact-persons-signet {
    position: absolute;
    right: 45px;
    bottom: 15px;
    color: $lightgrey;
    font-size: 60px;
    opacity: 0.4;
    z-index: -1;

    @media (min-width: $sm-view) {
      right: 30px;
      font-size: 28px;
      color: $specialGrey;
    }

    .glyphicons {
      position: relative;
      padding: 0 20px;
      right: -20px;

      @media (min-width: $sm-view) {
        right: auto;
        left: -10px;
      }
    }

    .signet-text {
      display: none;
      position: relative;

      @media (min-width: $sm-view) {
        display: block;
        font-size: 20px;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: -5px;
          left: -30px;
          width: 60px;
          height: 2px;
          background-color: $lightgrey;

          @media (min-width: $sm-view) {
            color: $specialGrey;
          }
        }
      }
    }
  }
}

#menu-contact-persons {
  @extend #menu-search;

  padding-top: 20px;

  &.sub-filter-open {
    right: 100vw;
  }

  .contact-persons-inner {
    .loading {
      padding-top: 40%;
      text-align: center;

      .loading-text {
        margin-top: 20px;
        font-size: 17px;
      }
    }

    .contact-persons-filter {
      height: 65vh;
      margin-bottom: 0;
      padding-left: 25px;
      overflow: scroll;
      list-style: none;

      @media (min-width: $sm-view) {
        position: absolute;
        top: 50px;
        right: 45px;
        width: calc(50% - 75px);
      }

      @media (min-width: $md-view) {
        width: calc(40% - 75px);
      }

      @media (min-width: $lg-view) {
        width: calc(30% - 75px);
      }

      & > li {
        & > a {
          display: block;
          position: relative;
          margin-left: 5px;
          padding: 0 0 10px 15px;
          color: $specialGrey;
          border-left: 1px solid $lightgrey;
          font-size: 14px;
          text-decoration: none;

          @include contrast;

          &:before {
            @include glyphicon-file;
            position: absolute;
            left: -25px;
            color: $lightgrey;

            @include contrast;
          }

          &.active:after {
            content: '';
            position: absolute;
            width: calc(100% + 30px);
            height: calc(100%);
            top: -5px;
            left: -30px;
            background-color: $white;
            z-index: -1;
          }
        }

        &:last-of-type > a {
          padding-bottom: 0;

          &.active:after {
            height: calc(100% + 10px);
          }
        }

        .contact-persons-sub-filter {
          position: fixed;
          top: 81px;
          right: -100vw;
          width: 100vw;
          height: calc(100vh - 83px);
          margin: 0;
          padding: 20px 45px 110px;
          background-color: $almostWhite;
          list-style: none;
          transition: 0.5s all ease-in-out;
          z-index: 9;

          &.sub-filter-open {
            right: 0;
          }

          &.contact-persons-open {
            right: 100vw;

            @media (min-width: $md-view) {
              right: 0;
            }
          }

          @media (min-width: $sm-view) {
            position: absolute;
            top: 0;
            right: 100%;
            width: 100%;
            height: 100%;
            padding: 0;

            .contact-persons-signet {
              display: none;
            }
          }

          & > li {
            & > a {
              display: block;
              margin-bottom: 3px;
              padding: 10px 20px;
              color: $specialGrey;
              background-color: $white;
              text-align: center;
              text-decoration: none;

              @include contrast;

              &.active:before {
                @include glyphicon-chevron-left;
                position: absolute;
                left: 5px;
                color: $lightgrey;
              }
            }
          }
        }
      }

      .contact-persons-title {
        display: block;
        position: relative;
        margin-bottom: 3px;
        padding: 10px 25px 10px 50px;
        color: $specialGrey;
        background-color: $white;
        text-decoration: none;
        cursor: pointer;

        @include contrast;

        &:before {
          @include glyphicon-folder-open;
          position: absolute;
          top: 0;
          left: 5px;
          padding: 10px;
          color: $lightgrey;
        }

        &:after {
          content: '';
          position: absolute;
          top: 5px;
          left: 37px;
          width: 1px;
          height: calc(100% - 10px);
          background-color: $lightgrey;
        }

        .contact-persons-back {
          display: block;
          position: absolute;
          top: 5px;
          right: 0;
          width: 20px;
          height: 100%;
          text-decoration: none;

          &:before {
            @include glyphicon-remove;
            color: $lightgrey;
          }
        }
      }
    }

    .contact-persons-wrapper {
      position: fixed;
      top: 81px;
      right: -100vw;
      width: 100vw;
      height: calc(100vh - 83px);
      margin: 0;
      padding: 20px 80px 20px;
      background-color: $almostWhite;
      overflow: scroll;
      transition: 0.5s all ease-in-out;

      @media (min-width: $sm-view) {
        position: absolute;
        top: 20px;
        left: 0;
        width: 50%;
        height: calc(80vh - 50px);
        padding: 25px;
        background-color: $white;
      }

      @media (min-width: $md-view) {
        width: 60%;
      }

      @media (min-width: $lg-view) {
        width: 70%;
      }

      &.open {
        right: 0;
      }

      .contact-persons-search {
        position: relative;
        margin-bottom: 25px;

        .contact-persons-search-input {
          padding: 5px 0;
          background-color: transparent;
          border: none;
          border-bottom: 2px solid $lightgrey;
          border-radius: 0;
          box-shadow: none;
          font-size: 12px;
        }

        .glyphicons {
          position: absolute;
          top: 10px;
          right: 0;
        }
      }

      .contact-person {
        margin-bottom: 30px;
        color: $lightgrey;
        font-size: 14px;

        @include contrast;

        @media (min-width: $md-view) {
          width: 50%;
          float: left;

          &.clearfix {
            clear: both;
          }
        }

        @media (min-width: $lg-view) {
          width: 33%;
        }

        &.filter-active:not(.search-result) {
          display: none !important;
        }

        .name {
          color: $specialGrey;
          font-size: 19px;

          @include contrast;
        }

        .department,
        .address {
          padding: 10px 0 15px 25px;
        }

        .contact {
          padding: 15px 0 0 25px;

          &:before {
            content: '';
            display: block;
            position: relative;
            top: -15px;
            left: -25px;
            width: 60px;
            height: 2px;
            background-color: $lightgrey;
          }

          a,
          div {
            display: block;
            position: relative;
            color: $lightgrey;
            text-decoration: none;

            @include contrast;

            &:before {
              @include glyphicon-fax;
              position: absolute;
              left: -25px;
            }

            &[href*="tel:"]:before {
              @include glyphicon-phone;
            }

            &[href*="mailto:"]:before {
              @include glyphicon-envelope;
            }
          }
        }
      }

      &:not(.open) .contact-persons-back:after {
        transform: rotate(180deg);
      }
    }
  }

  .contact-persons-back-wrapper {
    @extend .close-contact-persons-wrapper;

    .contact-persons-back {
      @extend .close-contact-persons;
    }
  }

  &:not(.sub-filter-open) .contact-persons-back:after {
    transform: rotate(180deg);
  }
}
