.page-addons {
  position: relative;
  top: 0;
}

#openings {
  a#openings-button {
    float: right;
  }

  .openings-inner {
    opacity: 1;
    box-shadow: none;
    position: static;

    .openings-footer {
      margin-left: 20px;
      box-shadow: none;
      display: block;

      a {
        cursor: pointer;
      }
    }
  }
}
