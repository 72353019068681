#contact-person, #addon-collection {
  position: relative;

  .contact-inner {
    display: block;
    box-shadow: none;
    width: 180px;

    .contact-body {
      margin-left: 20px;

      &:after {
        margin: 15px -15px;
      }
    }

    .contact-footer {
      margin-left: 20px;
      box-shadow: none;
      display: block;

      a {
        cursor: pointer;
      }

      #mail {
        a {
          cursor: pointer;
        }
      }
    }
  }
}
