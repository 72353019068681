.ajax-form {
  text-align: center;

  i.fa-spinner {
    margin-top: 50px;
    font-size: 40px;
  }
}

.contact-form {
  fieldset {
    z-index: 5;
  }
  nav.form-navigation.clearfix {
    z-index: 0;
  }

  input, textarea {
    background: $almostWhite;
    border-radius: 0px;
    border: none;
    box-shadow: none;
    color: $blue;
    font-weight: bold;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */ color: $blue; }
    &::-moz-placeholder { /* Firefox 19+ */ color: $blue; }
    &:-ms-input-placeholder { /* IE 10+ */ color: $blue; }
    &:-moz-placeholder { /* Firefox 18- */ color: $blue; }
  }

  textarea { resize: none; }

  label.col-xs-12 {
    font-weight: normal;
    text-align: left;
    margin-left: 10px;

    .required {
      margin-left: 3px;
      color: $red;
    }
  }

  .form-section>div {
    margin-bottom: 10px;
  }

  ul.nav {
    float: right !important;

    li.submit>button.btn {
      border-radius: 25px;
      border: none;
      box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.4);
      height: 35px;
      margin-right: 45px;
      margin-top: 20px;
      overflow: hidden;
      text-transform: uppercase;
      width: 130px;
      background: $almostWhiteTransparent;
      &:hover {
        background: darken($almostWhiteTransparent, 15);
      }

      &>.submit-slider {
        width: 180%;
        margin-left: -90%;
        transition: all 0.4s ease-in 0s;
        text-align: center;

        &.submitted {
          margin-left: 0%;

          & i.fa {
            border: 6px solid $green;
            padding-left: 3px;
            padding-top: 3px;
          }
        }

        & span {
          font-size: 12pt;
        }

        & i.fa {
          width: 35px;
          height: 35px;
          margin-top: -6px;
          margin-left: -6px;
          border: 6px solid $blue;
          border-radius: 25px;
          font-size: 17px;
          padding-top: 3px;
          padding-right: 4px;
          background: white;
          transition: all 0.4s ease-in 0s;
          color: $lightgrey;
        }
      }
    }
  }

  .genderswitch {
    -moz-user-select:none;
    -ms-user-select: none;
    -webkit-user-select:none;
    margin-bottom: 10px;
    margin-left: 15px;
    position: relative;
    width: 110px;

    .genderswitch-checkbox {
      display: none;
    }
    .genderswitch-label {
      border-radius: 20px;
      cursor: pointer;
      display: block;
      overflow: hidden;
    }
    .genderswitch-inner {
      display: block;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;
      width: 200%;
    }
    .genderswitch-inner:before, .genderswitch-inner:after {
      box-sizing: border-box;
      color: white;
      display: block;
      float: left;
      font-size: 14px;
      font-weight: bold;
      height: 30px;
      line-height: 30px;
      padding: 0;
      width: 50%;
    }
    .genderswitch-inner:before {
      background-color: $almostWhiteTransparent;
      color: $grey;
      content: "Männlich";
      padding-left: 10px;
      text-align: left;
    }
    .genderswitch-inner:after {
      background-color: $almostWhiteTransparent;
      color: $grey;
      content: "Weiblich";
      padding-right: 10px;
      text-align: right;
    }
    .genderswitch-switch {
      background: white;
      border-radius: 20px;
      border: 5px solid $blue;
      bottom: 0;
      display: block;
      font-size: 10pt;
      height: 30px;
      margin: 2px;
      padding: 3px;
      position: absolute;
      right: 76px;
      text-align: center;
      top: -2px;
      transition: all 0.3s ease-in 0s;
      width: 30px;
    }
    .genderswitch-checkbox:checked + .genderswitch-label .genderswitch-inner {
      margin-left: 0;
    }
    .genderswitch-checkbox:checked + .genderswitch-label .genderswitch-switch {
      right: 0px;
    }
  }
}

#contact-form-section-twentysix > p.help-block {
  text-align: left;
  padding-top: 6px;
}

div.event-selector {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-bottom: 15px;

  > h4.title {
    flex-basis: 100%;
  }

  > div.location {
    width: 100%;

    @media (min-width: 768px) {
      max-width: 50%;
    }

    > h5.title {
      font-weight: bold;
      color: $blue;
    }

    > div.events {
      > div.event {
        position: relative;

        @media (min-width: 1200px) {
          font-size: 14px;
        }

        > input {
          position: absolute;
          top: 1px;
          left: 5px;
          width: 15px;
          height: 15px;
          margin-top: 0;
        }

        > label {
          width: calc(100% - 25px);
          margin-left: 25px;
          text-align: left;

          display: flex;
          flex-direction: row;
          align-items: stretch;
          flex-wrap: wrap;

          > span.time {
            font-weight: bold;
            flex-shrink: 0;
            color: $blue;
            flex-basis: 100%;

            @media (min-width: 425px) {
              flex-basis: auto;
              max-width: 90px;
              margin-right: 4px;
            }

            @media (min-width: 1200px) {
              max-width: 95px;
            }
          }

          > span.title {
            font-weight: normal;

            @media (min-width: 425px) {
              max-width: calc(100% - 90px);
            }

            @media (min-width: 1200px) {
              max-width: calc(100% - 100px);
            }

            > s {
              vertical-align: middle;
            }

            > span.no-capacity {
              font-weight: normal;
              font-style: italic;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  > span.help-inline {
    flex-basis: 100%;
    padding: 5px;
  }
}
