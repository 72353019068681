#contact-person, #addon-collection {
  position: relative;

  .contact-inner {

    .contact-body {
      margin-left: 20px;

      &:after {
        margin: 15px -15px;
      }
    }
  }
}
