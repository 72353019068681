//Contact-Button Color settings

&.yellow {

 #contact-person-nodetype {
   > a {
     background-color: $yellow;
   }

   .contact-inner {
     h5 {
       color: $yellow;
     }
   }

   .contact-footer {

     i.glyphicons {
       color: $yellow;
     }

     a:hover {
       color: $yellow;
     }
   }
 }
}

&.green {

  #contact-person-nodetype {
    > a {
      background-color: $green;
    }

    .contact-inner {
      h5 {
        color: $green;
      }
    }
    .contact-footer {
      i.glyphicons {
        color: $green;
      }

      a:hover {
        color: $green;
      }
    }
  }
}

&.red {

  #contact-person-nodetype {
    > a {
      background-color: $red;
    }

    .contact-inner {
      h5 {
        color: $red;
      }
    }
    .contact-footer {
      i.glyphicons {
        color: $red;
      }

      a:hover {
        color: $red;
      }
    }
  }
}

&.blue {
  #contact-person-nodetype {
    > a {
      background-color: $blue;
    }

    .contact-inner {
      h5 {
        color: $blue;
      }
    }
    .contact-footer {
      i.glyphicons {
        color: $blue;
      }

      a:hover {
        color: $blue;
      }
    }
  }
}

#contact-person-nodetype {
  position: relative;
  max-width: 205px;

  #contact-image-wrapper {
    border-radius: 100%;
    overflow: hidden;
    width: 180px;
    float: right;

    .contact-image {
      padding-bottom: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

  }

  &.neos-contentelement-active {
    > a.edit-person {
      opacity: 1;
      transition: opacity .1s;
    }
  }

  > a#contact-button-nodetype {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    color: $white;
    display: block;
    padding: 24px;
    margin-top: 10px;
    float: right;

    i.glyphicons {
      font-size: 30px;
    }
  }

  > a.edit-person {
    position: absolute;
    top: -48px;
    right: -48px;
    opacity: 0;
    padding: 0 16px;
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    color: $white;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #222222;
    background-image: none;
    border: none;
    text-shadow: none;
    border: 1px solid #3f3f3f;

    &:hover {
      color: #00b5ff;
    }
  }

  .contact-inner {
    display: block;
    padding: 10px;

    h5 {
      margin-top: 0;
      font-size: 22px;
    }

    .contact-body {
      padding-left: 22px;
      p {
        margin-bottom: 0;
      }

      &:after {
        content: '';
        display: block;
        width: 65px;
        border-bottom: 2px solid $grey;
        margin: 15px 0;
        margin-left: -22px;
      }
    }

    .contact-footer {
      i.glyphicons {
        margin-right: 5px;
        margin-top: 2px;
      }

      a {
        color: $grey;
        word-wrap: break-word;

        &:hover {
          text-decoration: none;
        }
      }

      #mail {
        a {
          display: inline-block;
          line-height: 1.3;
        }
      }

      #floor-room {
        margin-top: 5px;

        #room {
          margin-left: 21px;
          line-height: 1.3;
        }
      }
    }
  }
}
