//color settings

.gallery {
  .gallery-item {
    .hovereffect {
      width: 100%;
      height: 100%;
      float: left;
      overflow: hidden;
      position: relative;
      text-align: center;
      cursor: default;
      background: $grey;

      &:hover {
        img {
          opacity: 0.6;
          filter: alpha(opacity=60);
          -webkit-transform: translate3d(0,0,0);
          transform: translate3d(0,0,0);
        }
      }

      &:hover {
        p {
          opacity: 1;
          filter: alpha(opacity=100);
          -webkit-transform: translate3d(0,0,0);
          transform: translate3d(0,0,0);
        }
      }

      &:hover {
        .overlay:before {
          opacity: .5;
          filter: alpha(opacity=100);
          -webkit-transform: translate3d(0,0,0);
          transform: translate3d(0,0,0);
        }
      }

      .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        padding: 3em;
        text-align: center;

        &:before {
          position: absolute;
          top: 20px;
          right: 20px;
          bottom: 20px;
          left: 20px;
          background: $almostWhiteTransparent;
          content: '';
          opacity: 0;
          filter: alpha(opacity=0);
          -webkit-transition: opacity 0.35s, -webkit-transform 0.45s;
          transition: opacity 0.35s, transform 0.45s;
          -webkit-transform: translate3d(-20px,0,0);
          transform: translate3d(-20px,0,0);
        }
      }

      img {
        display: block;
        position: relative;
        max-width: none;
        width: calc(100% + 60px);
        -webkit-transition: opacity 0.35s, -webkit-transform 0.45s;
        transition: opacity 0.35s, transform 0.45s;
        -webkit-transform: translate3d(-40px,0,0);
        transform: translate3d(-40px,0,0);
      }

      p {
        color: $white;
        padding-top: 35%;
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transition: opacity 0.35s, -webkit-transform 0.45s;
        transition: opacity 0.35s, transform 0.45s;
        -webkit-transform: translate3d(-10px,0,0);
        transform: translate3d(-10px,0,0);

        span.glyphicons {
          font-size: 30px;
        }
      }
    }
  }
}
