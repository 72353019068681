.sub-menu-nav {
  
  a {

    i.glyphicons {
      font-size: 19px;

      &.glyphicons-remove {
        font-size: 16px;
        margin-top: 2px;
        margin-right: 1.5px;
        margin-left: 1.5px;
      }
    }
  }
}
