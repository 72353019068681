// color settings
&.yellow {
  .neos-nodetypes-assetlist-assetlist {
    .asset-item {
      > .asset-title {
        border-left-color: $yellow;
      }
    }
  }
}

&.red {
  .neos-nodetypes-assetlist-assetlist {
    .asset-item {
      .asset-title {
        border-left-color: $red;
      }
    }
  }
}

&.blue {
  .neos-nodetypes-assetlist-assetlist {
    .asset-item {
      .asset-title {
        border-left-color: $blue;
      }
    }
  }
}

&.green {
  .neos-nodetypes-assetlist-assetlist {
    .asset-item {
      .asset-title {
        border-left-color: $green;
      }
    }
  }
}


.neos-nodetypes-assetlist-assetlist {

  .asset-item {
    position: relative;
    margin-bottom: 20px;

    &:hover {
      .filetypes {
        transform: translate3d(60px,0,0);
        transition: All .3s;
      }

      .glyphicons {
        transform: translate3d(-60px,0,0);
        transition: All .3s;
        transition-delay: 0.1s;
      }
    }

    .filetypes {
      font-size: 24px;
      position: absolute;
      top: 8px;
      left: 0;
      transform: translate3d(0,0,0);
      transition: All .3s;
      transition-delay: 0.1s;
    }

    .glyphicons {
      font-size: 24px;
      position: absolute;
      top: 8px;
      left: 60px;
      z-index: 0;
      transform: translate3d(60px,0,0);
      transition: All .3s;
      transition-delay: 0.1s;
    }

    .asset-title {
      font-size: 16px;
      display: inline-block;
      margin-left: 30px;
      border-left: 1px solid $grey;
      background-color: $white;

      position: relative;
      z-index: 9;

       p {
        margin-bottom: 0;
        margin-left: 10px;
        font-size: 12px;

        &:first-child {
          font-size: 15px;
        }
      }
    }
  }
}

.tabs {
  .neos-nodetypes-assetlist-assetlist {
    .asset-title {
      background-color: $almostWhite;
    }
  }
}
