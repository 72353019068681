//Contact-Button Color settings

&.yellow {

 #contact-person, #addon-collection {
   > a {
     background-color: $yellow;
   }

   .contact-inner {
     h5 {
       color: $yellow;
     }
   }

   .contact-footer {

     i.glyphicons {
       color: $yellow;
     }

     a:hover {
       color: $yellow;
     }
   }
 }
}

&.green {

  #contact-person, #addon-collection {
    > a {
      background-color: $green;
    }

    .contact-inner {
      h5 {
        color: $green;
      }
    }
    .contact-footer {
      i.glyphicons {
        color: $green;
      }

      a:hover {
        color: $green;
      }
    }
  }
}

&.red {

  #contact-person, #addon-collection {
    > a {
      background-color: $red;
    }

    .contact-inner {
      h5 {
        color: $red;
      }
    }
    .contact-footer {
      i.glyphicons {
        color: $red;
      }

      a:hover {
        color: $red;
      }
    }
  }
}

&.blue {
  #contact-person, #addon-collection {
    > a {
      background-color: $blue;
    }

    .contact-inner {
      h5 {
        color: $blue;
      }
    }
    .contact-footer {
      i.glyphicons {
        color: $blue;
      }

      a:hover {
        color: $blue;
      }
    }
  }
}

#contact-person, #addon-collection {
  position: relative;

  &.neos-contentelement-active {
    > a.edit-person {
      opacity: 1;
      transition: opacity .3s;
    }
  }

  .spacing {
    margin-bottom: 20px;
  }

  > a#contact-button, a#addon-button {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    color: $white;
    display: block;
    padding: 11px;
    margin-top: 10px;

    i.glyphicons {
      font-size: 15px;
    }
  }

  > a.edit-person {
    position: absolute;
    top: -48px;
    right: -8px;
    opacity: 0;
    padding: 0 16px;
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    color: $white;
    font-size: 14px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    width: auto;
    height: 40px;
    line-height: 40px;
    background-color: #222222;
    background-image: none;
    border: none;
    text-shadow: none;
    border: 1px solid #3f3f3f;
  }

  .contact-inner {
    display: none;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: $white;
    padding: 10px;
    min-width: 170px;
    box-shadow: 0px 1px 10px 0 rgba(0,0,0,0.3);

    &.active {
      display: block;
      z-index: 9;
    }

    .close-contact {
      color: $grey;

      i {
        font-size: 14px;
      }
    }

    h5 {
      margin-top: 0;
      font-size: 17px;
    }

    .contact-body {
      p {
        font-size: 12px;
        margin-bottom: 0;
      }

      &:after {
        content: '';
        display: block;
        width: 65px;
        border-bottom: 2px solid $grey;
        margin: 15px 0;
      }
    }

    .contact-footer {
      font-size: 12px;

      i.glyphicons {
        margin-right: 5px;
        margin-top: 2px;
      }

      a {
        color: $grey;
        word-wrap: break-word;

        &:hover {
          text-decoration: none;
        }
      }

      #mail {
        a {
          display: inline-block;
          line-height: 1.3;
        }
      }

      #floor-room {
        margin-top: 5px;

        #room {
          margin-left: 21px;
          line-height: 1.3;
        }
      }
    }
  }
}

#addon-collection {
  padding: 0;
}

.page-addons {
  padding-left: 0;
}

@media (max-width: 400px) {
  #addon-button, #contact-button, #openings-button {
    width: 33px !important;
    height: 33px !important;
    padding: 9px !important;
  }
}

@media (max-width: 321px) {
  #addon-button, #contact-button, #openings-button {
    width: 27px !important;
    height: 27px !important;
    padding: 6px !important;
  }
}
