.archived-switch {
  float: left;
  @media (min-width: $sm-view) {
    float: right;
    margin-top: 40px;
  }

  .archived-switch-button {
    padding: 5px 8px;
    display: block;
    text-transform: uppercase;

    &:hover {
      text-decoration: none;
      background-color: $specialGrey;
      color: $white;
    }

    &.active {
      background-color: $specialGrey;
      color: $white;
    }

    .glyphicons {
      margin-top: 2px;
      margin-right: 2px;
    }
  }
}
