
.breadcrumb-menu {
  .category {

    .category-number {
      font-size: $categoryNumberLandingLG;

      &::after {
        width: 80px;
        left: -30px;
      }
    }
    .category-title {
      font-size: $categoryTitleLandingLG;
    }
  }

  .normal, .active {

    &:before {
      margin-bottom: 4px;
    }

    a {
      font-size: $categoryActiveLandingLG;
    }
  }

  .current {
      font-size: $categoryCurrentLandingLG;
  }

}
