//colormsettings

&.yellow {
  .sub-menu-content {
    #sub-menu {
      nav {
        ul.nav-stacked {
          > li {
            a {
              &:hover {
                background-color: $yellow;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

&.red {
  .sub-menu-content {
    #sub-menu {
      nav {
        ul.nav-stacked {
          > li {
            a {
              &:hover {
                background-color: $red;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

&.green {
  .sub-menu-content {
    #sub-menu {
      nav {
        ul.nav-stacked {
          > li {
            a {
              &:hover {
                background-color: $green;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

&.blue {
  .sub-menu-content {
    #sub-menu {
      nav {
        ul.nav-stacked {
          > li {
            a {
              &:hover {
                background-color: $blue;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

//menu styles
.sub-menu-nav {
  margin-top: 10px;
  display: inline-block;

  a {
    background-color: $almostWhite;
    padding: 3px 10px;
    margin-right: 5px;
    display: inline-block;

    i.glyphicons {
      font-size: 17px;

      &.glyphicons-remove {
        font-size: 14px;
        margin-top: 2px;
        margin-right: 1.5px;
        margin-left: 1.5px;
      }
    }
  }
}

.sub-menu-content {
  display: block;
  margin-top: 2px;
  background-color: $almostWhite;

  #sub-menu {
    nav {
      margin-bottom: -5px;

      ul.nav-stacked {
        > li {
          position: relative;
          float: left;
          margin:0;

          a {
          padding-left: 25px;

            &:before {
              content: "\E037";
              font-family: 'Glyphicons Regular';
              vertical-align: top;
              line-height: 1;
              position: absolute;
              top: 12px;
              left: 6px;
            }

            &[href*='//']:before {
              content: '\E389';
              font-family: 'Glyphicons Regular';
              vertical-align: top;
              line-height: 1;
              position: absolute;
              top: 12px;
              left: 6px;
            }
          }
        }
      }
    }
  }
}
