.post-type-filter-menu {
  float: left;
  list-style: none;
  margin-top: 40px;
  padding: 0;

  li.filter-button {
    float: left;
    margin-right: 10px;
    padding: 5px 8px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: $almostWhite;
    }

    a {
      text-transform: uppercase;
      &:hover {
        text-decoration: none;
      }
      .glyphicons {
        margin-top: 2px;
        margin-right: 2px;
      }
      .filetypes {
        margin-top: 2px;
        margin-right: 2px;
      }
    }
    &.active {
      background-color: $specialGrey;
      a {
        color: $white;
      }
    }
  }
}
