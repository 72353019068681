#home {
  header {
    position: absolute;
    width: calc(100% - 30px);
    margin: 0 15px;
    z-index: 101;

    .header-logo {
      position: relative;
      padding: 10px;
      background-color: white;
      float: left;
      z-index: 9;
    }

    @media (max-width: $sm-view - 1) {
      .menu-triggers .nav-button:not(.menu-open) .icon-bar {
        background-color: $white;
      }
    }

    // .menu-triggers .sub-triggers .move-down {
    //   .search-button,
    //   .lettering-controls {
    //     display: none;
    //   }
    // }
  }

  main {
    width: 100%;

    .content {
      position: relative;
      height: 100vh;
      background-size: cover;
      background-position: center center;
      padding-left: 15px;
      padding-right: 15px;
      overflow: hidden;

      .content-inner {
        padding-top: 70px;
        color: white;

        h1 {
          font-size: 35px;
        }
      }
      .social-menu {
        position: absolute;
        bottom: 15px;
        z-index: 1;
        a {
          color: $white;

          i.fa {
            font-size: 28px;
            margin-right: 5px;

            &.last-child {
              margin-right: 0;
            }
          }
        }
      }

      .home-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        video {
          position: absolute;
          top: -100%;
          bottom: -100%;
          left: -100%;
          right: -100%;
          height: 100%;
          margin: auto;
        }
      }
    }

    .title {
      margin: 0 0 5px;
      position: absolute;
      width: 200px;
      bottom: 100px;
      color: white;
      text-shadow: 0 0 3px $grey;

      @media (min-width: $sm-view) {
        bottom: 55px;
        right: 20px;
      }
    }

    .sponsors {
      position: absolute;
      max-width: 200px;
      background: white;
      text-align: center;
      bottom: 50px;

      @media (min-width: $sm-view) {
        bottom: 0;
        right: 20px;
      }

      .swiper-slide {
        float: left;
        height: 50px;
        width: 240px;
        max-width: 100%;
        padding: 5px 10px;

        img {
          cursor: pointer;
          width: 100%;
          height: auto;
        }
      }
    }
  }

  footer {
    position: absolute;
    bottom: 15px;
    width: 100vw;
    z-index: 0;

    .footer-top {
      position: fixed;
      @media (min-width: $sm-view) {
        bottom: 14vh;
      }
      bottom: 15px;

      right: 1.5vw;
      width: inherit;

      .footer-logo img {
        max-width: 40%;
        @media (max-width: $lg-view) {max-width: 60%;}
        @media (max-width: $md-view) {max-width: 80%;}
        @media (max-width: 767px) {max-width: 20%;}
        @media (max-width: 550px) {max-width: 25%;}
        @media (max-width: 450px) {max-width: 33%;}
        @media (max-width: 350px) {max-width: 40%;}
      }
    }

    a {
      color: white;
    }

    .footer-logo {
      display: none;
    }

    .footer-menu {
      float: right;
      padding-top: 10px;
      margin-right: 10px;
      width: 140px;

      a {
        font-weight: 400;
        text-transform: uppercase;

        &:hover {
          text-decoration: none;
        }
      }

      .footer-menu-item {
        margin-right: 5px;

        &:after {
          content: '';
          border-right: 1px solid white;
          margin-left: 6px;
        }

        + .last-item {
          margin-right: 0;

          &::after {
            display: none;
          }
        }
      }
    }
    .copyright {
      display: none;
    }
  }
}

@media (min-width: $sm-view) {
  #home {
    header {
      width: 100%;
      margin: 10vh 0 0;
    }

    main {
      position: relative;
      width: 75%;
      margin-top: 10vh;

      .content {
        height: 80vh;
        background-size: cover;
        padding-left: 30px;
        padding-right: 30px;

        .content-inner {
          padding-top: 70px;
          color: white;

          h1 {
            font-size: 35px;
          }
        }

        .social-menu {
          position: absolute;
          bottom: 15px;

          a {
            i.fa {
              font-size: 35px;
              margin-right: 5px;
            }
          }
        }
      }
    }


    footer {
      display: block;
      width: 22%;
      position: relative;
      float: right;
      top: 65vh;

      a {
        color: $grey;
      }

      .footer-logo {
        display: block;
      }

      .footer-menu {
        float: none;
        margin-left: 30px;
        margin-top: -5px;

        .footer-menu-item {
          font-weight: 400;


          &:after {
            border-right: 1px solid $grey;
          }
        }
      }
    }
  }
}

@media (min-width: $md-view) {
  #home {
    footer {
      width: 22%;
      top: 61vh;
    }
  }
}

@media (min-width: $lg-view) {
  #home {
    footer{
      .footer-menu {
        width: 160px;
      }
    }
  }
}

@media (min-width: 2560px){
  #home {
    footer{
      .footer-menu {
        margin-left: 60px;
      }
    }
  }
}
