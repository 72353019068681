.last-notice-asset {
  display: none;
  @media (min-width: $sm-view) {display: block;}
  position: absolute;
  right: 0;
  top: 33vh;
  @media (max-width: $sm-view) and (min-width: 500px) {top: 29vh;}
  z-index: 5;
  width: 200px;
  @media (max-width: 500px) {margin-right: 10%;}

  h4 {
    font-size: 15px;
    text-align: center;
    margin-left: -15px;
    margin-right: -15px;

    @media (max-width: 450px) {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  .asset-wrapper {
    @media (min-width: 450px) {border: 1px solid $grey;}
    padding: 10px;
    margin-right: 0;

    .asseticon {
      font-size: 28px;
      padding: 5px;
      float: left;
      margin-top: 17%;
      @media (min-width: 400px) {margin-top: 12%;}
      @media (min-width: 500px) {margin-top: 7%;}
      @media (min-width: 900px) {margin-top: 4%;}
      @media (min-width: $lg-view) {margin-top: 1.5%;}
    }

    .assettitle {
      margin: 0px;
      min-height: 50px;
      padding-left: 10px;
      margin-left: 50px;
      border-left: 2px solid $grey;
      width: auto;
    }
  }
}
