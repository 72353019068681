#landing {
  header {
    position: absolute;
    width: calc(100% - 30px);
    margin: 0 15px;
    background-color: $white;
    z-index: 101;

    .header-logo {
      position: relative;
      padding: 10px;
      background-color: white;
      z-index: 9;

      a {
        display: table;
      }
    }
  }

  main {
    width: 100%;

    a {
      color: $grey;

      &:hover {
        border-radius: 0;
      }
    }

    .header-image {
      height: 80vh;
      background-size: cover;
      position: relative;

      .gradient {

        padding-top: 70px;
        padding-left: 15px;
        padding-right: 15px;

        height: inherit;
        position: relative;

        @import 'Breadcrumb-Menu/landing-mobile.scss';

        //color settings

        &.yellow {
          .announcement {
            border-color: $yellow;

            a {
              color: $yellow;
              font-weight: bold;
            }
          }
        }

        &.red {
          .announcement {
            border-color: $red;

            a {
              color: $red;
              font-weight: bold;
            }
          }
        }

        &.blue {
          .announcement {
            border-color: $blue;

            a {
              color: $blue;
              font-weight: bold;
            }
          }
        }

        &.green {
          .announcement {
            border-color: $green;

            a {
              color: $green;
              font-weight: bold;
            }
          }
        }
        .announcement {
          padding: 5px;
          border: 1px solid $grey;

          @media screen and (orientation: landscape) {
            display: none;
          }

          position: absolute;
          bottom: 20px;
          width: 60%;

          .announcement-content {
            background-color: $almostWhiteTransparent;
            padding: 15px;

            h4 {
              font-size: 90%;
            }

            p {
              font-size: 90%;
            }
          }
        }
      }
    }
    .content {
      background-size: cover;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 25px;

      &.yellow {
        .neos-nodetypes-text {
          a {
            color: $yellow;
          }
        }
      }

      &.red {
        .neos-nodetypes-text {
          a {
            color: $red;
          }
        }
      }

      &.blue {
        .neos-nodetypes-text {
          a {
            color: $blue;
          }
        }
      }

      &.green {
        .neos-nodetypes-text {
          a {
            color: $green;
          }
        }
      }

      > .row {
        position: relative;
      }

      #addon-collection {
        // width: 180px;
        padding: 10px;

        .neos-nodetypes-text {
          font-size: 12px;
        }

        @media (max-width: 991px) {
          #addon-button {margin-right: 10px;}
        }
      }

      @import 'Submenu/mobile.scss';
      @import 'Contact-Person/mobile.scss';
      @import 'Openings/landing-mobile.scss';

      //Content NodeTypes
      @import 'NodeTypes/text-with-image.scss';
      @import 'NodeTypes/tabs.scss';
      @import 'NodeTypes/collapsibles.scss';
      @import 'NodeTypes/assetlist.scss';
      @import 'NodeTypes/gallery.scss';
      @import 'NodeTypes/contact-person.scss';

      .content-inner {
        padding-top: 20px;



        h1 {
          font-size: 35px;
        }
      }
    }
  }

  footer {
    padding-left: 15px;
    width: 100%;
    margin-bottom: 30px;

    .divider {
      margin: 15px 0;

      &:after {
        content: '';
        display: block;
        width: 224px;
        position: relative;
        left: -15px;
        border-bottom: 2px solid $grey;
      }
    }

    .footer-top .footer-logo img {
      max-width: 40%;
      @media (max-width: $lg-view) {max-width: 60%;}
      @media (max-width: $md-view) {max-width: 80%;}
      @media (max-width: 767px) {max-width: 20%;}
      @media (max-width: 550px) {max-width: 25%;}
      @media (max-width: 450px) {max-width: 33%;}
      @media (max-width: 350px) {max-width: 40%;}
    }

    a {
      color: $grey;
    }

    .footer-menu {
      margin-left: 30px;
      padding-top: 5px;

      a {
        font-weight: 400;
        text-transform: uppercase;

        &:hover {
          text-decoration: none;
        }
      }

      .footer-menu-item {
        margin-right: 5px;

        &:after {
          content: '';
          border-right: 1px solid $grey;
          margin-left: 6px;
        }

        + .last-item {
          margin-right: 0;

          &::after {
            display: none;
          }
        }
      }
    }
  }
}

@media (min-width: $sm-view) {
  #landing {
    header {
      width: 100%;
      margin: 10vh 0 0;
    }

    main {
      position: relative;
      width: 75%;
      margin-top: 10vh;

      .header-image {
        width: 100%;
        height: 60vh;

        .gradient {
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 30px;

          @import 'Breadcrumb-Menu/landing-sm.scss';

          .announcement {
            display: block;
            position: absolute;
            bottom: 50px;
            width: 60%;

            .announcement-content {
              background-color: $almostWhiteTransparent;
              padding: 15px;

              h4 {
                font-size: 100%;
              }

              p {
                font-size: 100%;
              }
            }
          }
        }

      }

        .content {
          background-size: cover;
          padding-left: 30px;
          padding-right: 0;

          > .row {
            position: relative;
          }

          .breadcrumb-menu {
            position: absolute;
            top: 70px;
          }

          @import 'Submenu/sm.scss';
          @import 'Contact-Person/sm.scss';
          @import 'Openings/landing-sm.scss';

          .content-inner {
            padding-top: 20px;
         h1 {
            font-size: 35px;
          }
        }
      }
    }
    footer {
      width: 100%;
      padding-left: 30px;
      position: relative;
      margin-bottom: 50px;

      .footer-top {
        float: right;
        width: 22%;
        position: fixed;
        bottom: 10vh;
        right: 1vw;

          a {
              color: $grey;
          }

          .footer-menu {
            float: none;
            width: auto;
            margin-left: 30px;
            margin-top: -5px;

            .footer-menu-item {
               font-weight: 400;


            &:after {
              border-right: 1px solid $grey;
              }
            }
        }
      }
      .divider {
        &:after {
          left: -30px;
        }
      }

      .copyright {
        width: 200px;
      }
    }
  }
}

@media (min-width: $md-view) {
  #landing {
    main {
      .content {
        margin-top: 50px;
        @import 'Contact-Person/md.scss';
        @import 'Openings/md.scss';
      }
    }
  }
}

@media (min-width: $lg-view) {
  #landing {
    main {
      .header-image {
        height: 80vh;
        .gradient {
          padding-left: 30px;
          padding-right: 30px;

          @import 'Breadcrumb-Menu/landing-lg.scss';
        }
      }
      .content {
        margin-top: 70px;
        @import 'Submenu/lg.scss';
        @import 'Contact-Person/lg.scss';
        @import 'Openings/lg.scss';

        .content-inner {
          padding-top: 60px;
        }
      }
    }

  }
}
