.alert-box {
  background: rgba(0,0,0,0.6);
  border-radius: 1px;
  border: 1px solid $yellow;
  display: block;
  margin-top: -10%;
  min-height: 60px;
  padding: 10px;
  position: absolute;
  width: 96%;
  @media (max-width: 480px) { width: 91%; }
  @media (min-width: $sm-view) { width: 72%; top: 420px; }
  @media (min-width: $md-view) { width: 55%; }
  @media (min-width: $lg-view) { width: 47%; top: 450px }
  @media (min-width: 1440px) { width: 39%; }
  @media (min-width: 1800px) { top: 490px; }
  @media (min-width: 2500px) { top: 550px; }

  a {
    color: unset;
    text-decoration: unset;
  }

  .inner {
    margin-top: 10px;
    height: 100%;

    .alert-icons {
      color: $yellow;
      font-size: 30px;
      left: 4%;
      position: absolute;
      top: 37%;
      width: 92%;

      .glyphicons {
        margin: 0;
      }
    }

    .content-wrapper {
      left: 15%;
      margin-top: -2%;
      position: relative;
      top: 5%;
      width: 70%;

      p {
        margin: 0;
      }
    }
  }
}

.darken-overlay { display: none; }

@media (max-width: 500px) {
  .alert-box {
    z-index: 111;
    margin: 0;
    top: 40vh;
    width: 90vw;
    left: 5vw;
  }

  .darken-overlay {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.7);
    z-index: 110;

    .close-alert {
      color: $white;
      font-size: 35px;
      line-height: 20px;
      position: absolute;
      right: 15px;
      top: 12px;

      &:hover {
        color: $almostWhite;
      }
    }
  }
}
