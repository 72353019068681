// Color settings

&.yellow {
  .panel-group {
    .panel {
     .panel-heading {
       border-bottom-color: $yellow;
     }
    }
  }
}

&.red {
  .panel-group {
    .panel {
     .panel-heading {
       border-bottom-color: $red;
     }
    }
  }
}

&.blue {
  .panel-group {
    .panel {
     .panel-heading {
       border-bottom-color: $blue;
     }
    }
  }
}

&.green {
  .panel-group {
    .panel {
     .panel-heading {
       border-bottom-color: $green;
     }
    }
  }
}

.panel-group {
  .panel {
    border-radius: 0;
    border: none;

    .panel-heading {
      background-color: transparent;
      border-bottom: 1px solid $grey;
      padding: 10px 10px 10px 0px;

      .panel-title {
        > a {
          display: block;
          position: relative;

          &:focus, &:hover {
            text-decoration: none;
          }

          &:before,
          &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 6px;
            right: 3px;
            width: 11px;
            height: 3px;
            background-color: $specialGrey;
            transition: all .3s
          }

          &.collapsed:after {
            transform: rotate(90deg);
          }
        }
      }
    }

    .panel-body {
      border: none;
      background-color: $almostWhite;
    }
  }
}
