.breadcrumb-menu {
  .category {
    .category-number {
      font-size: $categoryNumberLandingSM;

      &::after {
        width: 70px;
        left: -30px;
      }
    }
    .category-title {
      font-size: $categoryTitleLandingSM;
    }
  }

  .normal, .active {

    a {
      font-size: $categoryActiveLandingSM;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .current {
      font-size: $categoryCurrentLandingSM;
      font-weight: 900;
  }
}
