.breadcrumb-menu {
  .category {
    .category-number {
      font-size: $categoryNumberSM

      &::after {
        width: 70px;
        left: -30px;
      }
    }
  }

  .normal, .active {

    a {
      font-size: $categoryActiveSM;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .current {
      font-size: $categoryCurrentSM;
      font-weight: 900;
  }
}
