#openings {
  position: relative;

  .openings-inner {
    display: block;
    box-shadow: none;
    width: 180px;

    .openings-body {
      margin-left: 20px;

      &:after {
        margin: 15px -15px;
      }
    }

    .openings-footer {
      margin-left: 20px;
      box-shadow: none;
      display: block;

      a {
        cursor: pointer;
      }
    }
  }
}
